import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';
import {numberWithCommas} from "../../../utils/utils";

type Params = {
    tableName: string;
    numberOfRows: number;
    numberOfDeadRows: number;
};

const NumberOfDeadRowsInsight: React.FC<TableInsightsProps> = ({ severity, params, defaultOpen}) => {

    const calculateRatio = (deadRows: number, numberOfRows: number) => {
        // Avoid division by zero
        if (numberOfRows === 0) {
            return "0";
        }

        const ratioToTen = (deadRows / numberOfRows) * 10;

        return `${ratioToTen.toFixed(1)}:10`;
    }

    const getDetails = () => {
        const { tableName, numberOfDeadRows, numberOfRows } = params as Params;
        return (
            <div>
                The Table <code>{tableName}</code> has an estimated {numberWithCommas(numberOfRows)} live rows, and {numberWithCommas(numberOfDeadRows)} dead rows (a ratio of {calculateRatio(numberOfDeadRows, numberOfRows)} dead to live).
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                A high number of dead rows can significantly impact database performance by:
                {' '}
                <ul>
                    <li>
                        <b>Increasing Table Size:</b> Dead rows add bulk, slowing down read operations.
                    </li>
                    <li>
                        <b>Reducing Query Performance:</b> Queries, especially full-table scans, must filter out dead rows,
                        increasing execution time.
                    </li>
                    <li>
                        <b>Increasing I/O Costs:</b> More disk reads are required, placing additional load on the system.
                    </li>
                </ul>
            </div>
    )
    }

    const getRemediation = () => {
        const { tableName } = params as Params;
        return (
            <div>
                To address the accumulation of dead rows, the following steps can be taken:
                {' '}
                <ul>
                    <li>
                        <b>Adjust Autovacuum Settings:</b> Tune the autovacuum settings to make it more responsive to
                        changes, such as lowering the autovacuum threshold for the table in question.
                        This could involve adjusting parameters like <code>autovacuum_vacuum_threshold</code> and
                        <code>autovacuum_vacuum_scale_factor</code> to better control when autovacuum kicks in for THIS
                        table only.
                        For example, to set the threshold to 1000 rows:
                        <div style={{
                            backgroundColor: '#f4f4f4',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <code>ALTER TABLE {tableName} SET (autovacuum_vacuum_threshold = 1000);</code></div>
                    </li>

                    <li>
                        <b>Run VACUUM:</b> Configuring autovacuum settings to trigger at lower thresholds for this table
                        is generally recommended, as it allows PostgreSQL to manage dead rows automatically.
                        However, if more precise control over timing is needed—such as running vacuum during off-peak
                        hours like late at night—you can schedule manual VACUUM jobs instead.
                        <div style={{
                            backgroundColor: '#f4f4f4',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <code>VACUUM {tableName};</code></div>
                    </li>
                    <li>
                        <b>Run VACUUM FULL (if necessary):</b> <code>VACUUM FULL</code> reclaims space by physically
                        removing dead rows and reorganizing the table, reducing its on-disk size. Unlike
                        standard <code>VACUUM</code>,
                        <code>VACUUM FULL</code> locks the table during execution, which can impact table availability,
                        so it should be used only in cases of significant bloat or when regular vacuuming isn’t
                        sufficient.
                    </li>
                    <li>
                        <b>Analyze and Optimize Table Workload:</b> Review the usage patterns on this table to reduce the frequency of updates and deletes.
                        For instance, periodic purges or archiving less critical data can help reduce the volume of changes.
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Number Of Dead Rows'} severity={severity} defaultOpen={defaultOpen}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                    <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default NumberOfDeadRowsInsight;