import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Select as SelectMui } from '@mui/material';
import { styled } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { ReactComponent as ArrowIcon } from '@icons/arrow-down.svg';
import {GroupTitleContainer} from "./Select.styled";
import {capitalizeFirstLetter} from "../../utils/utils";

export type SelectListItem = {
  id: number | string;
  name: string | any;
  val: string;
  group?: string;
};
interface SelectProps {
  name: string;
  list: SelectListItem[];
  defaultValue?: string;
  value: string;
  onSelect: (value: any) => void;
  minWidth?: string;
  changeValueOnPress?: boolean;
  disabled?: boolean;
  defaultOpen?: boolean;
  isSorted?: boolean;
}
export const SelectInput = styled(SelectMui)`
  .MuiSelect-select {
    padding: 4px 8px;
  }
`;
export const Label = styled(InputLabel)`
  top: -4px;
`;

const renderMenuList = (list: any[]) => {
    const useGroups = list.some((item) => item?.group);
  const groups = useGroups ? [...new Set(list.map(item => item.group))] : [];
  if (!groups.length) {
    return list.map((item) => (
      <MenuItem data-cy={`select-item-${item.id}`} key={item.id} value={item.val}>
        {item.name}
      </MenuItem>
    ));
  }
  else {
      const listComponents = [];

    const groupByGroupKey = list.reduce((acc, item) => {
        if (!acc[item.group]) {
            acc[item.group] = [];
        }
        acc[item.group].push(item);
        return acc;
    }, {});
    groups.sort((a, b) => a.localeCompare(b)).map((group) => {
            listComponents.push(
                <MenuItem divider={true} disabled={true} data-cy={`select-item-group-${group}`} key={`group-${group}`} value={`group-${group}`}>
                    <GroupTitleContainer>{capitalizeFirstLetter(group)}</GroupTitleContainer>
                </MenuItem>
            )
            {groupByGroupKey[group].map((item: any) => {
                    listComponents.push(
                        <MenuItem data-cy={`select-item-${item.id}`} key={item.id} value={item.val}>
                            {item.name}
                        </MenuItem>
                    )
                })}
    });
    return listComponents;
  }
}

const Select: FunctionComponent<SelectProps> = ({
  name,
  onSelect,
  list = [],
  minWidth = 150,
  value = '',
  disabled = false,
  defaultOpen = false,
  isSorted = true
}) => {
  const theme: any = useTheme();
  const selectList = isSorted ? list.sort((a, b) => String(a?.name).localeCompare(String(b?.name))) : list;
    return (
    <FormControl sx={{ minWidth }} size="small" disabled={disabled}>
      <Label id="demo-select-small">{name}</Label>
      <SelectInput
        IconComponent={(props: any) => (
          <ArrowIcon className={`material-icons ${props.className}`} title="select" fill={theme.colors.info.main} />
        )}
        defaultOpen={defaultOpen}
        labelId="demo-select-small"
        id="demo-select-small"
        value={value || ''}
        label={name}
        onChange={(e: any) => {
            onSelect(e.target.value)
        }}
      >
        {renderMenuList(selectList)}
      </SelectInput>
    </FormControl>
  );
};

export default Select;
