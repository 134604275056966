import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    tableCount: string;
};

const LargeNumberOfTables: React.FC<TableInsightsProps> = ({ severity, params }) => {
    const title = "Large Number Of Tables Used"
    const { tableCount } = params as Params || {};

    const getDetails = () => {
        return (
            <div>
                The query execution plan detected joins across <b>{tableCount?.toLocaleString()}</b> tables,<br/>
                significantly increasing complexity and resource consumption.
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                A query which requires joining a large number of tables leads to longer execution times and higher resource usage.
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <ol>
                    <li>
                        Evaluate whether all tables are necessary. If some are not required by the application, remove them.
                    </li>
                    <li>
                        Use the <b>AI Advice</b> for index recommendations to optimize the joins
                    </li>
                </ol>
            </div>
        )
    }

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default LargeNumberOfTables;
