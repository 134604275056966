import React from 'react';
import HighNumberOfRowsReturned from "./HighNumberOfRowsReturned";
import ResultSetSize from "./ResultSetSize";
import QueryUseStar from "./QueryUseStar";
import HighNumberOfSortedRowsReturned from "./HighNumberOfSortedRowsReturned"
import RowsRead from "./RowsRead";
import UpdateWithoutAWhere from "./UpdateWithoutAWhere";
import DeleteWithoutAWhere from "./DeleteWithoutAWhere";
import LargeNumberOfTables from "./LargeNumberOfTables";
import LargeDataScanned from "./LargeDataScanned";

const QueryInsightTypes = {
    HIGH_NUMBER_OF_ROWS_RETURNED: 'HIGH_NUMBER_OF_ROWS_RETURNED',
    RESULT_SET_SIZE: 'RESULT_SET_SIZE',
    HIGH_NUMBER_OF_ROWS_SORTED: 'HIGH_NUMBER_OF_ROWS_SORTED',
    ROWS_READ: 'ROWS_READ',
    QUERY_USE_STAR: 'QUERY_USE_STAR',
    HIGH_NUMBER_OF_ROWS_READ: 'HIGH_NUMBER_OF_ROWS_READ',
    UPDATE_WITHOUT_WHERE: 'UPDATE_WITHOUT_WHERE',
    DELETE_WITHOUT_WHERE: 'DELETE_WITHOUT_WHERE',
    LARGE_NUMBER_OF_TABLES: 'LARGE_NUMBER_OF_TABLES',
    LARGE_DATA_SCANNED: 'LARGE_DATA_SCANNED'
} as const;

type QueryInsightTypes = typeof QueryInsightTypes[keyof typeof QueryInsightTypes];

export type QueryInsightsProps = {
    type?: string;
    insightInfo: {
        "priority": string;
        "query_id": string;
        "db_id": number,
        "host_id": number,
        "category": string,
        "sub_category": QueryInsightTypes,
        "created_at": string;
    }
    severity: number;
    params: object | null ;
};

type QueryInsightsMapperProps = {
    insights: QueryInsightsProps[];


};

const QueryInsightsMapper: React.FC<QueryInsightsMapperProps> = ({ insights }) => {
    return (
        <>
            {insights
                .sort((a, b) => (a.severity > b.severity ? 1 : -1))
                .map((insight, index) => {
                    const { insightInfo, severity, params } = insight;

                    switch (insightInfo?.sub_category as QueryInsightTypes) {
                        case QueryInsightTypes.HIGH_NUMBER_OF_ROWS_RETURNED:
                            return (
                                <HighNumberOfRowsReturned
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                    params={params}
                                />
                            )
                        case QueryInsightTypes.HIGH_NUMBER_OF_ROWS_SORTED:
                            return(
                                <HighNumberOfSortedRowsReturned
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                    params={params}
                                />
                            )
                        case QueryInsightTypes.RESULT_SET_SIZE:
                            return(
                                <ResultSetSize
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                    params={params}
                                />
                            )
                        case QueryInsightTypes.HIGH_NUMBER_OF_ROWS_READ:
                            return(
                                <RowsRead
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                    params={params}
                                />
                            )
                        case QueryInsightTypes.QUERY_USE_STAR:
                            return(
                                <QueryUseStar
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                />
                            )
                        case QueryInsightTypes.UPDATE_WITHOUT_WHERE:
                            return (
                                <UpdateWithoutAWhere
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                />
                            )
                        case QueryInsightTypes.DELETE_WITHOUT_WHERE:
                            return (
                                <DeleteWithoutAWhere
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                />
                            )
                        case QueryInsightTypes.LARGE_NUMBER_OF_TABLES:
                            return (
                                <LargeNumberOfTables
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                    params={params}
                                />
                            )
                        case QueryInsightTypes.LARGE_DATA_SCANNED:
                            return  (
                                <LargeDataScanned
                                    key={`${insightInfo?.sub_category}-${index}`}
                                    severity={severity}
                                    params={params}
                                />
                            )

                        default:
                            return null;
                    }
                })}
        </>
    );
};

export default QueryInsightsMapper;
