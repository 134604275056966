import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { RestClientProvider } from './auth/RestClientAuthProvider';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme, themeMui } from './theme';
import { ThemeProvider as ThemeMui } from '@mui/material';
import { FRONTEND_VERSION, CURRENT_ENV, SENTRY_CONFIG, isDevelopment, isProduction } from './envioments-state';
import { PostHogProvider } from 'posthog-js/react';
import { FronteggProvider } from '@frontegg/react';
import { isDemo } from './common/consts';
import { CustomerlyProvider } from 'react-live-chat-customerly';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

// print the current version to the console for debugging
console.log(
  `version=${`env=${CURRENT_ENV} version=${import.meta.env.VITE_METIS_VERSION ?? 'develop'} release=${JSON.stringify(
    SENTRY_CONFIG.release
  )}`}`
);

const options = {
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST
};

Sentry.init({
  ...SENTRY_CONFIG,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: CURRENT_ENV,
  enabled: isDevelopment() || isProduction(),
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  beforeBreadcrumb(breadcrumb, hint: any) {
    if (breadcrumb.category === 'ui.click') {
      const { target } = hint.event;
      if (target?.innerText) {
        breadcrumb.message = target.innerText;
      }
    }
    return breadcrumb;
  },
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

const customerlyAppId = import.meta.env.VITE_CUSTOMERLY_APP_ID || '03694eff';
const authOptions = {
  keepSessionAlive: true,
  routes: isDemo
    ? {
        loginUrl: '',
        authenticatedUrl: ''
      }
    : undefined
};

root.render(
  <RecoilRoot>
    <CustomerlyProvider appId={customerlyAppId}>
      <PostHogProvider apiKey={isProduction() && import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <ThemeProvider theme={theme}>
          <ThemeMui theme={themeMui}>
            <GlobalStyles />
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <FronteggProvider
                  contextOptions={{
                    baseUrl: import.meta.env.VITE_FRONTEGG_BASE_URL,
                    clientId: import.meta.env.VITE_FRONTEGG_CLIENT_ID
                  }}
                  hostedLoginBox={!isDemo}
                  authOptions={authOptions}
                >
                  <RestClientProvider>
                    <App />
                  </RestClientProvider>
                </FronteggProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </ThemeMui>
        </ThemeProvider>
      </PostHogProvider>
    </CustomerlyProvider>
  </RecoilRoot>
);
