import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';


const QueryUseStar: React.FC<TableInsightsProps> = ({ severity}) => {
    const title = "Select * Was Used";

    const getDetails = () => {
        return (
            <div>
                The SQL command uses <b><code style={{backgroundColor: '#E0E0E0'}}>SELECT *</code></b> instead of specifying the actual columns.
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li>
                        <b>Increased data transfer:</b> Retrieves unnecessary data, slowing down query performance.
                    </li>
                    <li>
                        <b>Reduce Query Performance:</b> Slows execution, especially with large or complex data types.
                    </li>
                    <li>
                        <b>Increase Maintenance Complexity:</b> Makes code harder to maintain and more prone to issues with schema changes
                    </li>
                    <li>
                        <b>Hinder Index Optimization:</b>Selecting only the necessary columns helps
                        suggest covering indexes, which can improve performance by allowing
                        the database to use indexes for the entire query without accessing the table.
                    </li>
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                Replace <code style={{backgroundColor: '#E0E0E0'}}>SELECT *</code> with the actual columns: <code style={{backgroundColor: '#E0E0E0'}}>SELECT column1, column2…</code>
            </div>
        )
    }

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default QueryUseStar;
