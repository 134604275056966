import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import { Details, DetailsWrapper } from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import { Label } from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import { TableData, TableInsightsProps } from "../types";
import { Container, FlexFullWrapper, IconWrapper } from '../Insights.styled';
import Flex from '../../../core/Flex';
import { TableContainer } from "@mui/material";
import TableItem from "../../Table/TableItem";
import { Params } from "react-router-dom";
import { headerMappingTableData } from "../utils";

const RowsRead: React.FC<TableInsightsProps> = ({ severity, params }) => {
    const title = 'Number Of Rows Read';
    const { maxCost, results } = params as Params || {};

    const transformParamsForTable = (headerMapping: { [key: string]: string }) => {
        if (!Array.isArray(results)) {
            console.error("Results is not an array");
            return [];
        }

        return results.map((item) => {
            const transformedItem: { [key: string]: any } = {};

            Object.keys(item).forEach((key) => {
                const properName = headerMapping[key] || key;
                transformedItem[properName] = item[key] ?? 'N/A';
            });
            return transformedItem;
        });
    };

    const getDetails = () => {
        const transformedData = transformParamsForTable(headerMappingTableData);
        return (
            <div>
                The SQL cost is <b>{maxCost ? parseInt(maxCost)?.toLocaleString() : 'N/A'}</b><br />
                <Flex>
                    <TableContainer className="ag-theme-material"
                                    style={{ maxHeight: '400px', overflowX: 'hidden', maxWidth: '100%', padding: '5px' }}>
                        <TableItem
                            data={transformedData}
                            emptyErrorMessage="No Data was found"
                            headHeight="190px"
                        />
                    </TableContainer>
                </Flex>
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                Reading a large number of rows requires many IO operations. That might resolve into:

                <ul>
                    <li>Large row queries strain storage, causing slow response times and Disk I/O overload.</li>
                    <li>Multi-user environments suffer from growing query sizes, impacting database performance.</li>
                    <li>Extensive result sets consume memory, possibly resorting to disk-based temporary storage.</li>
                    <li>Large queries may cause contention issues with shared locks and blocking.</li>
                    <li>Remote database access encounters substantial network traffic, leading to delays.</li>
                    <li>Resource-intensive queries impede database scalability.</li>
                    <li>Extensive result sets hamper application performance, requiring query optimization.</li>
                </ul>
            </div>
        );
    };

    const getRemediation = () => {
        return (
            <div>
                Use the <b>AI Advice</b> for index recommendations.<br />
                It automatically identifies and suggests optimal indexing strategies based on the current schema.
            </div>
        );
    };

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>Insight Details</Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>Impact</Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>Remediation Plan</Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default RowsRead;