import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';
import TableItem from "../../Table/TableItem";

type Params = {
    column1: string;
    column2: string;
    duplicatedNames: string[];
}[];

const DuplicateIndexesInsight: React.FC<TableInsightsProps> = ({ severity, params, defaultOpen}) => {

    const getDetails = () => {
        const duplicates = params as Params;
        return (
            <div>
                The following indexes use the same columns in the same order, making some of them redundant:

                <TableItem
                    headHeight={'320px'}
                    data={
                        duplicates.map(duplicateItem => {
                            return {
                                ['Index Name']: duplicateItem.column1,
                                ['Same As']: duplicateItem.column2,
                                ['Columns or Expressions']: duplicateItem.duplicatedNames.join(', ')
                            }
                        })
                    }
                />
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <h3>Issues with Duplicate Indexes</h3>
                <ul>
                    <li>
                        <strong>Wasted Storage Space:</strong> Duplicate indexes consume additional storage space
                        without providing any benefit, leading to inefficient use of disk resources.
                    </li>
                    <li>
                        <strong>Reduced Performance:</strong> Maintaining duplicate indexes adds unnecessary overhead
                        during insert, update, and delete operations, potentially reducing overall database performance.
                    </li>
                    <li>
                        <strong>Increased Maintenance:</strong> Regular maintenance tasks like vacuuming and reindexing
                        take longer and require more resources due to the additional indexes.
                    </li>
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <ol>
                    <li>
                        <strong>Drop one of the Redundant Indexes:</strong> Use the <code>DROP INDEX</code> command to
                        remove the duplicate indexes. The script deletes the first index.
                        <pre><code>DROP INDEX IF EXISTS index_name;</code></pre>
                    </li>
                    <li>
                        <strong>Verify Index Usage:</strong> Ensure the remaining indexes are used efficiently using the
                        Monitoring page.
                    </li>
                </ol>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Duplicate Indexes'} severity={severity} defaultOpen={defaultOpen}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default DuplicateIndexesInsight;