import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    schemaName: string;
    tableName: string;
};

const NoIndexesInsight: React.FC<TableInsightsProps> = ({ severity, params, defaultOpen}) => {

    const getDetails = () => {
        const { schemaName, tableName } = params as Params;
        return (
            <div>
                The table {schemaName}.{tableName} has no indexes
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Slow Query Performance:</b></li>
                    Without indexes, the database must perform full table scans for queries, which can be
                    time-consuming, especially for large tables. This significantly slows down data retrieval
                    operations.
                    <li><b>High Resource Consumption:</b></li> Full table scans consume more CPU and memory resources,
                    leading to increased load on the database server and potential performance degradation for other
                    operations.
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b>Identify Frequently Queried Columns</b>
                <br/>
                Analyze query patterns to determine which columns are frequently used in WHERE clauses, JOIN conditions, ORDER BY, and GROUP BY clauses. These columns are prime candidates for indexing. Use the widget “Top Queries”.
                <br/>
                <b>Create Indexes on Identified Columns</b>
                <br/>
                Based on the analysis, create indexes on the identified columns. The Query insights should guide these recommendations.
            </div>
        )
    }

    return (
        <InsightsAccordion title={'No Indexes'} severity={severity} defaultOpen={defaultOpen}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default NoIndexesInsight;