import Flex from "../../core/Flex";
import Typography from "../../core/Typography"
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {ReactComponent as Arrow} from '@icons/accordion-arrow.svg';
import styled from 'styled-components';
import {SeverityLabel} from "../../components/SeverityLabel/SeverityLabel";
import {AssertionTab} from "../../components/AssertionsTab/AssertionTab";
import {getFactsAsKeyVal} from "../../utils/utils";
import LottieAnimation from "../../core/Lottie";
import ElephantAnimation from "../../../assets/lotties/elephant.json";
import DolphinAnimation from "../../../assets/lotties/dolphin.json";
import {RDBMS} from "../../utils/constants";
import {Assertion} from "../../types/Assertion.type";
import {insightTitleConversion} from "./utils";
import { useEdges } from "reactflow";
import { useEffect, useState } from "react";

interface InsightsAccordionListProps {
  tableInsights?: any;
  rdbms?: RDBMS;
  indexInsights?: any;
  indexReportAssertions?: boolean;
  dataType?:string;
}



export const AccordionItem = ({ title, body, defaultOpen}: {title: any, body: any, defaultOpen :boolean}) => {
  return <>
        <Accordion style={{width: '100%'}} defaultExpanded={defaultOpen}>
        <AccordionSummary
          expandIcon={<ArrowIcon title="arrow" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>
         {body}
        </AccordionDetails>
      </Accordion>
  </>
}

export const ArrowIcon = styled(Arrow)`
  stroke: ${({ theme }) => theme.colors.new.primary.main}};
  height: 24px;
  width: 24px;
  transform: rotate(90deg);
`;
export const AccordionItemIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
`;

 
export const InsightsAccordionList = ({
    tableInsights,
    rdbms,
    indexInsights, 
    indexReportAssertions, 
    dataType }: InsightsAccordionListProps) => {

    if(indexInsights) {
      return (
        <>
        {
          indexInsights.map((insight: any, idx: number) =>  <AccordionItem
          key={idx}
          title={
            <Flex style={{ width: '100%' }} justify="space-between">
              <Typography h4>{insight.title || 'title'}</Typography>
              <SeverityLabel priority={insight?.priority} />
            </Flex>}
          body={
          <AssertionTab
            insightsView={true}
            assertions={indexInsights}
            indexReportAssertions={indexReportAssertions}
            headHeight="0"
            facts={undefined}
            assertionCurrentIndex={0}
            tableInfos={[]}
            isLoading={false}
            selectedQuery={0}
            tableName={undefined}
            schemaName={undefined}
            tableItem={indexInsights} />}
            defaultOpen={indexInsights.length === 1 ? true: false}
            sideBarOn={false}
          >
      </AccordionItem>)
        }
        </>
      )
  }

  const noInsightFound = (tableInsights: any,indexInsights: any ) => 
    tableInsights?.table_insights?.assertions?.fail.filter((insight: any) => 
      (insight?.condition?.event?.priority || insight?.priority) !== 5).length === 0 || 
        (!indexInsights && dataType === 'INDEXES');

  if(noInsightFound(tableInsights,indexInsights)) {
    return (<div style={{display: 'flex', width: '100%', height: '70%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  { rdbms && <LottieAnimation width={300} height={300} lottieJsonFile={RDBMS.postgres === rdbms ? ElephantAnimation: DolphinAnimation} loop={true} />}
                  
                  <Typography style={{marginTop: 15}} h1> No Insights Found. All Good</Typography>
            </div>)
  }

    const getUpdatedAssertionDetails = (insight: any): Assertion => ({
      ...insight,
      title: insight?.title || insight?.rule?.title,
      ruleId: insight?.ruleId || insight?.rule?.id,
      priority: insight?.priority || insight?.rule?.priority || insight?.condition?.event?.priority
    });

  
  return (
    <>
      {tableInsights?.table_insights?.assertions?.fail.filter((insight: any) => (insight?.condition?.event?.priority || insight?.priority) !== 5).map((insight: any, idx: number) => (
      <AccordionItem
        key={idx}
        title={
          <Flex style={{width: '100%'}} justify="space-between">
            <Typography h3>{insightTitleConversion(insight?.rule?.title, tableInsights?.table_insights) || 'title'}</Typography>
            <SeverityLabel priority={insight?.condition?.event?.priority || insight?.priority || insight?.rule?.priority} />
          </Flex>}
          body={<AssertionTab
            insightsView={true}
            assertions={[getUpdatedAssertionDetails(insight)]}
            headHeight="230px"
            facts={getFactsAsKeyVal(tableInsights?.table_insights?.metrics)}
            assertionCurrentIndex={0}
            tableInfos={[]}
            isLoading={false}
            selectedQuery={0}
            tableName={tableInsights?.table_name}
            schemaName={tableInsights?.schema_name}
            tableItem={tableInsights} />} defaultOpen={false}         >
      </AccordionItem>
    ))}
    </>
  )
}

