import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import { Details, DetailsWrapper } from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import { Label } from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import { TableInsightsProps } from "../types";
import { Container, FlexFullWrapper, IconWrapper } from '../Insights.styled';
import Flex from '../../../core/Flex';
import { TableContainer } from "@mui/material";
import TableItem from "../../Table/TableItem";
import {headerMappingLargeDataScanned } from "../utils";


type Params = {
    totalDataReadInGB: string,
    results: [],
};
const LargeDataScanned: React.FC<TableInsightsProps> = ({ severity, params }) => {
    const title = 'Large Data Scanned (Estimated)';
    const { totalDataReadInGB, results } = params as Params || {};

    const transformParamsForTable = (headerMapping: { [key: string]: string }) => {
        if (!Array.isArray(results)) {
            console.error("Results is not an array");
            return [];
        }

        return results.map((item) => {
            const transformedItem: { [key: string]: any } = {};

            Object.keys(item).forEach((key) => {
                const properName = headerMapping[key] || key;
                transformedItem[properName] = item[key] ?? 'N/A';
            });
            return transformedItem;
        });
    };

    const getDetails = () => {
        const transformedData = transformParamsForTable(headerMappingLargeDataScanned);
        return (
            <div>
                The total size of estimated data read is <b>{totalDataReadInGB.toLocaleString()} (GB) </b><br />
                The following table shows the estimated data read from each table.
                <Flex>
                    <TableContainer className="ag-theme-material"
                                    style={{ maxHeight: '300px', overflowX: 'hidden', maxWidth: '60%' ,padding: '5px'}}>
                        <TableItem
                            data={transformedData}
                            emptyErrorMessage="No Data was found"
                            headHeight="190px"
                        />
                    </TableContainer>
                </Flex>
                <br/>
                    <b>Warning:</b> Relying solely on estimated data sizes from a MySQL execution plan to analyze query
                    performance might be unreliable, as the estimates are based on statistics rather than actual data,
                    which can lead to misleading performance analysis.
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                Reading a large amount of data increases query execution time, higher resource usage (such as CPU and memory),
                and potential slowdowns in overall system performance.
            </div>
        );
    };

    const getRemediation = () => {
        return (
            <div>
                <ul>
                    <li><b>Use proper indexing:</b> Ensure that queries are using appropriate indexes to reduce the amount of data scanned, improving performance.</li>
                    <li><b>Optimize query structure:</b> Rewrite queries to filter or limit data earlier in the execution, reducing the volume of data processed in joins or aggregations.</li>
                    <li><b>Partition large tables:</b> Consider partitioning large tables to reduce the amount of data scanned for certain queries, improving performance.</li>
                    <li><b>Analyze query execution plans:</b> Regularly review and adjust query execution plans to avoid inefficient access paths or joins.</li>
                </ul>
            </div>
        );
    };

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>Insight Details</Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>Impact</Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>Remediation Plan</Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default LargeDataScanned;