import InsightDetailsCard from '../InsightDetailsCard/InsightDetailsCard';
import Flex from '../../core/Flex';
import {
    AssertionContainer,
    AssertionDetailsContainer,
    AssertionDetailsTitleContainer,
    AssertionsTabContainer,
} from './AssertionTab.styled';
import { Label } from '../Ruler/Ruler.styled';
import { InvestigationDetail } from '../InvestigationDetail/InvestigationDetail';
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import { Assertion } from '../../types/Assertion.type';
import InsightLoader from '../Loader/Insight';
import { getInsightInvestigation } from '../../utils/automated-investgiation/automated-investigation';
import { useRecoilValue } from 'recoil';
import { debugModeState } from '../../atoms/debug-mode.atom';
import { analyticsTrack } from '../../utils/segment-analytics';
import LottieAnimation from '../../core/Lottie';
import SafeInnerHtml from '../../utils/SafeInnerHtml';
import { PriorityTextToNumber } from '../../enums';
import SplashScreenIcon from '../../../assets/lotties/ splash-screen.json';
import { useEffect, useState } from 'react';
import QueryInsightsMapper, { QueryInsightsProps } from '../InsightsAccordion/QueryInsights/QueryInsightsMapper';

const InvestigationTitle = {
    INSIGHT: 'Insight Details',
    IMPACT: 'Impact',
    REMEDIATION: 'Remediation Plan',
};

interface CombinedInsight {
    kind: string;
    data: Assertion | QueryInsightsProps;
    idx: number;
}

interface AssertionTabProps {
    assertions: Assertion[];
    facts?: any;
    assertionCurrentIndex?: number;
    setAssertionIndexCallback?: any;
    tableInfos: any[];
    isLoading?: boolean;
    selectedQuery?: any;
    changeTabActivity?: any;
    headHeight?: string;
    tableItem?: any;
    extensionsReportAssertions?: boolean;
    configurationsReportAssertions?: boolean;
    indexReportAssertions?: boolean;
    isSidebar?: boolean;
    insightsView?: boolean;
    tableName?: string;
    schemaName?: string;
    sideBarOn?: boolean
    queryInsights?: QueryInsightsProps[];
}

export const AssertionTab = ({
                                 assertions,
                                 isLoading,
                                 setAssertionIndexCallback,
                                 facts,
                                 tableInfos,
                                 changeTabActivity,
                                 selectedQuery,
                                 headHeight,
                                 tableItem,
                                 extensionsReportAssertions,
                                 indexReportAssertions,
                                 configurationsReportAssertions,
                                 isSidebar,
                                 tableName,
                                 schemaName,
                                 queryInsights,
                                 sideBarOn
                             }: AssertionTabProps) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const debugMode = useRecoilValue(debugModeState);

    const transformTypeToTitle = (type: string): string => {
        return type
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const cleanSuccessAssertions = (
        assertions: Assertion[],
        queryInsights?: QueryInsightsProps[]
    ): Assertion[] => {

        if (
            assertions.every(
                (assertion: Assertion) =>
                    (assertion?.condition?.event?.priority || assertion.priority) === PriorityTextToNumber.INFO)
             && (!queryInsights || queryInsights?.length === 0)
        ) {
            return [
                {
                    priority: PriorityTextToNumber.INFO,
                    title: 'All Checks passed',
                } as Assertion,
            ];
        }

        return assertions
            .filter((a) => (a.condition?.event?.priority || a?.priority) !== PriorityTextToNumber.INFO)
            .sort((assertionA: Assertion, assertionB: Assertion) => {
                const priorityA = assertionA?.condition?.event?.priority || assertionA?.priority;
                const priorityB = assertionB?.condition?.event?.priority || assertionB?.priority;
                return priorityA - priorityB;
            });
    };

    const sortedAssertions = cleanSuccessAssertions(assertions, queryInsights);

    const combinedInsights: CombinedInsight[] = [
        ...sortedAssertions.map((assertion, idx) => ({
            kind: 'assertion',
            data: assertion,
            idx,
        })),
        ...(queryInsights ?? []).map((insight, idx) => ({
            kind: 'queryInsight',
            data: {
                ...insight,
                priority:  insight.severity,
            },
            idx: idx + sortedAssertions.length,
        })),
    ].sort((a, b) => {
        const priorityA = a.data.priority ?? 0;
        const priorityB = b.data.priority ?? 0;
        return priorityA - priorityB;
    });

    useEffect(() => {
        if (combinedInsights.length > 0 && selectedIndex === null) {
            setSelectedIndex(0);
            setAssertionIndexCallback?.(0);
        }
    }, [combinedInsights, selectedIndex, setAssertionIndexCallback]);


    const selectedItem = combinedInsights[selectedIndex];

    const automatedInvestigation =
        selectedItem?.kind === 'assertion' &&
        (extensionsReportAssertions || configurationsReportAssertions || indexReportAssertions
            ? {
                investigation: <SafeInnerHtml unsafeInnerHtmlText={(selectedItem.data as Assertion)?.details} />,
                impact: <SafeInnerHtml unsafeInnerHtmlText={(selectedItem.data as Assertion)?.impact} />,
                remediation: <SafeInnerHtml unsafeInnerHtmlText={(selectedItem.data as Assertion)?.remediation} />,
            }
            : getInsightInvestigation(
                facts,
                (selectedItem.data as Assertion)?.ruleId,
                tableInfos,
                changeTabActivity,
                selectedQuery,
                selectedItem.data as Assertion,
                tableItem,
                tableName,
                schemaName
            ));

    return (
        <AssertionsTabContainer headHeight={headHeight}>
            {isLoading ? (
                <InsightLoader />
            ) : (
                sideBarOn && <AssertionContainer >
                    {combinedInsights.map((insight, idx) => (
                        <InsightDetailsCard
                            choosen={idx === selectedIndex}
                            index={idx}
                            isSidebar={isSidebar}
                            callback={() => {
                                setSelectedIndex(idx);
                                analyticsTrack('Performance|Query Details Insight', {
                                    index: idx,
                                    kind: insight.kind,
                                    data: insight.data,
                                });
                            }}
                            assertionDetails={
                                insight.kind === 'assertion'
                                    ? (insight.data as Assertion)
                                    : {
                                        title: transformTypeToTitle((insight.data as QueryInsightsProps).type),
                                        priority: (insight.data as QueryInsightsProps).severity,
                                    }
                            }
                            key={`insight-${idx}`}
                        />
                    ))}
                </AssertionContainer>
            )}

            {selectedItem?.kind === 'assertion' && automatedInvestigation ? (
                <AssertionDetailsContainer>
                    <AssertionDetailsTitleContainer justify={'space-between'}>
                        <Flex>
                            <Label weight={600}>
                                {debugMode &&
                                    `${(selectedItem.data as Assertion)?.category} - ${(selectedItem.data as Assertion)?.ruleId}`}
                            </Label>
                        </Flex>
                    </AssertionDetailsTitleContainer>
                    {[
                        {
                            title: InvestigationTitle.INSIGHT,
                            details: automatedInvestigation.investigation,
                            icon: <InsightsDetailsIcon width={24} />,
                            key: 0,
                        },
                        {
                            title: InvestigationTitle.IMPACT,
                            details: automatedInvestigation.impact,
                            icon: <Pendulum width={24} />,
                            key: 1,
                        },
                        {
                            title: InvestigationTitle.REMEDIATION,
                            details: automatedInvestigation.remediation,
                            icon: <Wrench width={24} />,
                            key: 2,
                        },
                    ].map((e) => (
                        <InvestigationDetail
                            isLoading={isLoading}
                            key={e.key}
                            title={e.title}
                            details={e.details}
                            icon={e.icon}
                        />
                    ))}
                </AssertionDetailsContainer>
            ) : selectedItem?.kind === 'queryInsight' ? (
                <AssertionDetailsContainer>
                    <QueryInsightsMapper insights={[selectedItem.data as QueryInsightsProps]} />
                </AssertionDetailsContainer>
            ) : (
                !isLoading && (
                    <Flex fw>
                        <LottieAnimation width={150} height={250} lottieJsonFile={SplashScreenIcon} loop={true} />
                    </Flex>
                )
            )}
        </AssertionsTabContainer>
    );
};
