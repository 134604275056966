import {TableData, LargeDataScannedTableData} from "./types";
import {v4 as uuidv4} from "uuid";

export function generateHeaderMapping<T>(mapping: { [key in keyof T]: string }): { [key in keyof T]: string } {
    return mapping;
}

export const headerMappingTableData = generateHeaderMapping<TableData>({
    schemaName: 'Schema Name',
    tableName: 'Table Name',
    accessMethod: 'Access Method',
    indexName: 'Index Name',
    rowsRead: 'Rows Read',
    rowsFiltered: 'Rows Filtered',
    cost: 'Cost',
    filter: 'Filter',
    timing: 'Timing',
    buffers: 'Buffers',
});

export const headerMappingLargeDataScanned = generateHeaderMapping<LargeDataScannedTableData>({
    tableName: 'Table Name',
    dataReadPerJoin: 'Estimated Data Read Per Join (GB)',
});

export const generateRandomHash = (size = 12) => {
    return uuidv4().replace(/-/g, '').slice(0, size);
};

