import { KyInstance } from 'ky/distribution/types/ky';
import * as Sentry from '@sentry/react';
import dayjs, { duration } from 'dayjs';

import {
  DBConfigType,
  DBExtensionResponse,
  DataIndex,
  DataTable,
  QueryStats,
  Rows,
  SuggestionResults,
  QueryStatisticsAndInsights,
  QueryInsightT,
  QueryStatisticDataItemT, QueryInsightItemT, Severity
} from '../reports/types';
import { analyticsTrack } from '../../../utils/segment-analytics';
import { RDBMS } from '../../../utils/constants';
import { SnackbarSeverityE } from '../../../enums/snackbar-severity.enum';
import { SnackbarStateI } from '../../../atoms/snackbar.atom';
import {
  ConfigurationInsightsProps
} from "../../../components/InsightsAccordion/ConfigurationsInsights/ConfigurationInsightsMapper";

export const EmptyReport = {
  rows: [],
  lastUpdateIsoDate: '',
  offset: 0,
  sortBy: 'insights',
  sortOrder: 'desc',
  sql: undefined
};

export const getStatisticQueryByIdAPI = async (
    restClient: KyInstance,
    projectApiKey: string,
    query_id: string,
    dbId: string
): Promise<QueryStatisticsAndInsights> => {
  try {
    const response = await restClient
        .get(`statistics/query`, {
          headers: {
            'x-api-key': projectApiKey,
          },
          searchParams: {
            dbId: dbId,
            queryId: query_id,
          },
        })
        .json();

    const { statistics: statisticsData, insights: insightsData } = response as {
      statistics: QueryStatisticDataItemT[];
      insights: any;
    };
    const queryStatistics: QueryStatisticDataItemT[] = statisticsData.map((item: any) => {
      const callsDelta = Number(item?.calls || 0);
      const totalExecTimeDelta = Number(item?.total_exec_time_delta || 0);

      let hourly_avg_duration: number;
      if (totalExecTimeDelta / callsDelta === Infinity) {
        hourly_avg_duration = totalExecTimeDelta;
      } else if (Number.isNaN(totalExecTimeDelta / callsDelta)) {
        hourly_avg_duration = +item.mean_exec_time;
      } else {
        hourly_avg_duration = totalExecTimeDelta / callsDelta;
      }

      return {
        ...item,
        mean_exec_time: Number(item?.mean_exec_time),
        created_at: item?.created_at,
        formatted_date: dayjs(item.created_at).format('MMM-DD-YY HH:00'),
        calls: Number(item?.calls ?? 0),
        calls_delta: callsDelta,
        calls_deltaRed: Math.abs(callsDelta),
        avg_duration_delta: callsDelta > 0 ? totalExecTimeDelta / callsDelta / 1000 ?? 0 : 0,
        hourly_avg_duration,
      };
    });

    const queryInsights: QueryInsightT = insightsData?.[0]?.queryCallsIncrease
        ? {
          queryCallsIncrease: {
            data: insightsData[0].queryCallsIncrease.data.map((item: QueryInsightItemT) => ({
              severity: Severity[item.priority as keyof typeof Severity] || 5,
              query_id: item.query_id,
              category: item.category,
              type: item.sub_category,
              created_at: item.created_at,
              params: insightsData[0].queryCallsIncrease.values || [],
            })),
          },
        }
        : {
          queryCallsIncrease: {
            data: [],
          },
        };

    analyticsTrack('getQueryStatisticById Success', { queryStatistics, queryInsights });

    return {
      queryStatistics,
      queryInsights,
    };
  } catch (error: any) {
    analyticsTrack('getQueryStatisticById Error', { error: error.message });

    return {
      queryStatistics: [],
      queryInsights: {
        queryCallsIncrease: {
          data: [],
        }
      }
    };
  }
};


export const getStatisticQueryAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  dbId: string,
  offset: number,
  sql?: string,
  sortBy?: string,
  sortOrder?: string
): Promise<{ rows: QueryStats[]; rdbms?: RDBMS | '' }> => {
  try {
    const { rdbms, data }: { data: QueryStats[]; rdbms?: RDBMS | '' } = await restClient
      .get(`statistics/query/top`, {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: {
          size: 50,
          dbId,
          offset: offset,
          ...(sql && { statement: sql.trim() }),
          ...(sortBy && { sortBy }),
          ...(sortOrder && { sortOrder })
        }
      })
      .json();
    analyticsTrack('getStatisticQuery Success');
    return {
      rows: data.map((q: QueryStats) => ({
        ...q,
        query: q?.query?.replaceAll('\\n', ' ')?.replaceAll('\\t', ' ')?.trim()
      })),
      rdbms
    };
  } catch (error: any) {
    analyticsTrack('QueryStatistics fetch Error');
    return EmptyReport;
  }
};

export const getStatisticQueryLastUpdatedDate = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string,
  offset: number
): Promise<{ lastUpdateIsoDate: string }> => {
  try {
    const { last_updated }: { last_updated: string } = await restClient
      .get(`statistics/query/lastUpdated`, {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: {
          size: 50,
          pmcDeviceId: pmcDeviceId,
          offset: offset
        }
      })
      .json();
    analyticsTrack('getStatisticQueryLastUpdatedDate Success', last_updated);
    return { lastUpdateIsoDate: last_updated };
  } catch (error: any) {
    analyticsTrack('getStatisticQueryLastUpdatedDate fetch Error');
    return EmptyReport;
  }
};

export const getInsightsByQueryIdAPI = async (
    restClient: KyInstance,
    projectApiKey: string,
    dbId: string,
    queryId: string,
    traceId: string
): Promise<any[]> => {
  try {
    const res: any = await restClient
        .get(`statistics/query/insights?dbId=${dbId}&queryId=${queryId}&traceId=${traceId}`, {
          headers: { 'x-api-key': projectApiKey }
        })
        .json();
    return res?.queries?.length ? res?.queries : [];
  } catch (error: any) {
    analyticsTrack('getInsightsByQueryId fetch Error');
    return [];
  }
};

export const getInsightsByTraceIdAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  traceId: string,
  dbId: string,
  queryId: string
): Promise<any[]> => {
  try {
    const res: any = await restClient
      .get(`traces/trace-by-query-id?traceId=${traceId}&dbId=${dbId}&queryId=${queryId}`, {
        headers: { 'x-api-key': projectApiKey }
      })
      .json();
    return res?.queries?.length ? res?.queries : [];
  } catch (error: any) {
    analyticsTrack('getInsightsByTraceId fetch Error');
    return [];
  }
};

export const getExtensionsReportAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  pmcDeviceId: string
): Promise<DBExtensionResponse | undefined> => {
  try {
    const res: DBExtensionResponse = await restClient
      .get('pmc-device/extensions', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: {
          pmcDeviceId
        }
      })
      .json();

    return res;
  } catch (error: any) {
    analyticsTrack('getExtensionsReportAPI fetch Error');
  }
};

export const getTableRowSizeAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  databaseId: string
): Promise<{ rows: DataTable[]; lastUpdateIsoDate: string; rdbms?: RDBMS | '' } | undefined> => {
  try {
    const res: any = await restClient
      .get('reports', {
        searchParams: {
          ['api-key']: projectApiKey ?? '',
          ['report-type']: 'numberOfRows',
          dbId: databaseId
        },
        headers: { 'x-api-key': projectApiKey }
      })
      .json();
    if (!res) return EmptyReport;
    const data: DataTable[] = Object.values(res?.data);
    const rowsLength: number = data.length === 1 ? 1 : data.length - 1;
    if (rowsLength) {
      const historyLength: number = (data[rowsLength] as DataTable)?.rows?.length - 1;
      const lastUpdateIsoDate: any = (data[rowsLength] as DataTable)?.rows?.[historyLength]?.time;

      return {
        rows: data.sort((a, b) =>
          `${a?.schema_name?.[0]?.value}.${a?.context?.table_name}` > `${b?.schema_name?.[0]?.value}.${b?.context?.table_name}`
            ? 1
            : -1
        ),
        lastUpdateIsoDate,
        rdbms: res?.rdbms
      };
    }
    return EmptyReport;
  } catch (error: any) {
    analyticsTrack('getTableRowSize fetch Error');
  }
};

export const getIndexUsageAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  databaseId: string,
  interval: string,
): Promise<{ rows: DataIndex[]; lastUpdateIsoDate: string } | undefined> => {
  try {
    const res: Rows = await restClient
      .get('reports', {
        searchParams: {
          ['api-key']: projectApiKey ?? '',
          ['report-type']: 'indexUsage',
          dbId: databaseId,
          duration: interval,
        }
      })
      .json();
    const data: DataIndex[] = Object.values(res?.data);

    if (data && Array.isArray(data) && data.length) {
      const historyLength: number = data[0]?.values?.length - 1;
      const lastUpdateIsoDate: any = data[0]?.values?.[historyLength]?.time;

      return {
        rows: data,
        lastUpdateIsoDate
      };
    }

    return EmptyReport;
  } catch (error: any) {
    analyticsTrack('getIndexUsageAPI fetch Error');
  }
};

export const getIndexTrendsAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  databaseId: string,
  interval: string,
  indexTrend: string,
  indexName: string,
  tableName: string,
  timeFrame: string
): Promise<{ rows: DataIndex[]; lastUpdateIsoDate: string } | undefined> => {
  try {
    const res: Rows = await restClient
      .get('reports/index-trends', {
        searchParams: {
          ['api-key']: projectApiKey ?? '',
          ['report-type']: 'indexUsage',
          dbId: databaseId,
          duration: interval,
          timeFrame,
          indexTrend,
          indexName,
          tableName
        }
      })
      .json();
    const data: DataIndex[] = Object.values(res?.data);

    if (data && Array.isArray(data) && data.length) {
      const historyLength: number = data[0]?.values?.length - 1;
      const lastUpdateIsoDate: any = data[0]?.values?.[historyLength]?.time;

      return {
        rows: data,
        lastUpdateIsoDate
      };
    }

    return EmptyReport;
  } catch (error: any) {
    analyticsTrack('getIndexUsageAPI fetch Error');
  }
};

export const getDbConfigReportAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  hostId: string
): Promise<DBConfigType | undefined> => {
  try {
    const configReportRes: DBConfigType = await restClient
      .get('projects/production-config', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: { offset: 0, hideProjectionIssues: false, hostId }
      })
      .json();
    return configReportRes;
  } catch (error: any) {
    analyticsTrack('getDbConfigReportAPI fetch Error');
  }
};
export const getDbConfigHistoryAPI = async (restClient: KyInstance, projectApiKey: string, hostId: string): Promise<any> => {
  try {
    const configHistoryRes: any = await restClient
      .get('db-details/config/change-log', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: { hostId }
      })
      .json();

    return configHistoryRes;
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getDbConfigHistoryAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    analyticsTrack('getDbConfigHistoryAPI fetch Error');
  }
};

export const getConfigurationsInsightsAPI = async (restClient: KyInstance, apiKey: string, hostId: string): Promise<ConfigurationInsightsProps[]> => {
  try {
    const insights: ConfigurationInsightsProps[] = await restClient
        .get('insights/config', {
          headers: {
            'x-api-key': apiKey
          },
          searchParams: { hostId }
        })
        .json();

    return insights;
  } catch (error: any) {
    const body = error?.response;
    Sentry.captureException(new Error(`Error-getConfigurationsInsightsAPI-${body?.status}`), {
      extra: {
        ErrorMessage: `StatusCode: ${body?.status}, URL:${body?.url}`
      }
    });
    analyticsTrack('getConfigurationsInsightsAPI fetch Error');
    return [];
  }
};

// need that
export const getTableReadWriteAnalysisAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  databaseId: string,
  tableName: string,
  interval: string
): Promise<any | undefined> => {
  try {
    const configReportRes: any = await restClient
      .get(`reports/table-analysis/${databaseId}/${tableName}`, {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: { interval }
      })
      .json();
    return configReportRes;
  } catch (error: any) {
    analyticsTrack('getTableReadWriteAnalysis fetch Error');
  }
};

export const getQuerySuggestionAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  queryId: string
): Promise<{
  isMySQL: boolean | undefined;
  queryResults: SuggestionResults[] | undefined;
  schemaResults: SuggestionResults[] | undefined
}> => {
  try {
    const resQueryItem: { queryResults: SuggestionResults[]; schemaResults: SuggestionResults[] } = await restClient
        .get(`optimizer/${queryId}`, {
          headers: {
            'x-api-key': projectApiKey
          }
        })
        .json();

    if (resQueryItem?.queryResults?.length) {
      analyticsTrack('getQuerySuggestionAPI Success', resQueryItem);
      const originalQuery = resQueryItem?.queryResults?.find((item) => item?.isOriginal);
      const allSchemaResultsLackCost = resQueryItem?.schemaResults?. length === 0 ? false : resQueryItem?.schemaResults?.every((item: SuggestionResults) => !item?.cost);

      const filteredSchemaResults = allSchemaResultsLackCost
          ? resQueryItem?.schemaResults
          : resQueryItem?.schemaResults?.filter((item: SuggestionResults) =>
              originalQuery?.cost && item?.cost && originalQuery?.cost >= item?.cost);

      return {
        schemaResults: filteredSchemaResults,
        queryResults: resQueryItem?.queryResults?.sort((itemA, itemB) =>
            (itemA?.cost || 10000) - (itemB?.cost || 10000)),
        isMySQL: allSchemaResultsLackCost
      };
    }

    return { queryResults: [], schemaResults: [], isMySQL: undefined };
  } catch (error: any) {
    analyticsTrack('getQuerySuggestionAPI Error');
    return { queryResults: [], schemaResults: [] , isMySQL: undefined};
  }
};
export const getQueryOptimizerAPI = async (
  restClient: KyInstance,
  projectApiKey: string,
  queryId: string,
  dbId: string,
  setSnackbarS: any
): Promise<boolean> => {
  try {
    const resQueryItem = await restClient
      .post('optimizer', {
        headers: {
          'x-api-key': projectApiKey
        },
        searchParams: { queryId, dbId }
      })
      .json();
    setSnackbarS((oldSnackbarState: SnackbarStateI) => {
      return {
        ...oldSnackbarState,
        open: true,
        severity: SnackbarSeverityE.success,
        text: `The Metis AI will optimize this query soon as possible.`
      };
    });
    return true;
  } catch (error: any) {
    setSnackbarS((oldSnackbarState: SnackbarStateI) => {
      return {
        ...oldSnackbarState,
        open: true,
        severity: SnackbarSeverityE.warning,
        text: 'Metis AI can`t optimize this query something wrong with this Query or Plan',
        descriptions: JSON.stringify(error)
      };
    });
  }
  return false;
};
