import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    tableName: string;
    schemaName: string;
};

const MissingPrimaryKeyInsight: React.FC<TableInsightsProps> = ({ severity, params, defaultOpen}) => {

    const getDetails = () => {
        const { schemaName, tableName } = params as Params;
        return (
            <div>
                The table {schemaName}.{tableName} doesn’t have a primary key
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Uniqueness and Integrity:</b></li>
                    Without a primary key, duplicate rows can exist, leading to data integrity issues.
                    <li><b>Efficient Data Retrieval:</b></li> Many database operations, such as indexing and joins, are
                    optimized based on the presence of a primary key. Without it, these operations can become less
                    efficient, leading to slower query performance.
                    <li><b>Referential Integrity:</b></li> Primary keys are often used to establish relationships
                    between tables. Without a primary key, it becomes difficult to create foreign key constraints,
                    weakening the relational structure of the database.{' '}
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        const {  tableName } = params as Params;
        return (
            <div>
                <b>Identify a Unique Column or Set of Columns</b><br/>
                Analyze the table's data to find a column or a combination of columns that uniquely identifies each row.<br/>
                If no such column(s) exist, consider adding a new column that can serve as a unique
                identifier.<br/><br/>
                <b>Alter the Table to Add a Primary Key</b> {' '}
                <ul>
                    <li>Once you have identified or created a suitable column, you can modify the table schema to add
                        the
                        primary key. Here’s how you can do it in SQL:<br/>
                        <b>Example 1: Adding a Primary Key to an Existing Column</b>
                        <div style={{
                            backgroundColor: '#f4f4f4',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <code>ALTER TABLE {tableName} ADD CONSTRAINT pk_{tableName} PRIMARY
                                KEY
                                (unique_column);</code></div>
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Missing a Primary Key'} severity={severity} defaultOpen={defaultOpen}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default MissingPrimaryKeyInsight;