import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';


const UpdateWithoutAWhere: React.FC<TableInsightsProps> = ({ severity}) => {
    const title = "UPDATE without a WHERE";

    const getDetails = () => {
        return (
            <div>
                The <b><code style={{backgroundColor: '#E0E0E0'}}>UPDATE</code></b> command is executed without
                a <b><code style={{backgroundColor: '#E0E0E0'}}>WHERE</code></b> clause.
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                <ol>
                    <li>
                        <b>Unintended Data Changes:</b> Updates all rows in the table, potentially causing significant data corruption or loss.
                    </li>
                    <li>
                        <b>Recovery Challenges:</b> Reverting unintended changes can be difficult and time-consuming.
                    </li>
                </ol>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b>Add a <code style={{backgroundColor: '#E0E0E0'}}>WHERE</code> Clause:</b> Limit the update to only
                 the intended rows.
            </div>
        )
    }

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default UpdateWithoutAWhere;
