import { RDBMS } from '../../../utils/constants';

export interface BaseContext {
  host: string;
  schema: string;
  table: string;
  db: string;
}

export type Value = {
  time: string;
  value: number;
};

export type IndexContext = BaseContext & {
  index: string;
  table_name: string;
  insights?: any;
};
export type DataTable = {
  schema_name: any[];
  context: IndexContext;
  table_size: Value[];
  rows: Value[];
  dead_rows_tuples: Value[];
  values: Value[];
};

export type DataIndex = {
  context: IndexContext;
  values: Value[];
};

export interface QueryStats {
  calls: number;
  created_at: string;
  mean_exec_time: number;
  query: string;
  query_id: string;
  trace_id?: string;
  severities?: any;
}

export interface QueryStatisticDataItemT {
  mean_exec_time: number;
  created_at: string;
  calls: number;
  calls_delta: number;
  total_exec_time: string;
  total_exec_time_delta: string;
}

export interface QueryInsightItemT {
  priority: string;
  query_id: string;
  category: string;
  sub_category: string;
  created_at: string;
  params: Record<string, any>[][];

}

export interface QueryInsightT {
  queryCallsIncrease: {
    data: QueryInsightItemT[] | [];
  };
}

export interface QueryStatisticsAndInsights {
  queryStatistics: QueryStatisticDataItemT[];
  queryInsights: QueryInsightT;
}

export type ReportAtomState = 'dataTable' | 'dataIndex' | 'dataQueryStats' | 'dataExtension' | 'dataDBConfig';
export type Rows = {
  rdbms: RDBMS;
  data: { [key: string]: DataIndex | DataTable };
};
export interface DBConfigType {
  id: string;
  config: Config[];
  updated_at: string;
}

export interface Config {
  name: string;
  setting: string;
  description: string;
}

export interface SuggestionResults {
  plan?: any;
  isGenericPlan?: boolean;
  codeBlock: string;
  description?: string;
  cost?: number;
  isOriginal?: boolean;
}

export interface Extension {
  name: string;
  default_version: string;
  installed_version: null | string;
  comment: null | string;
  insight?: any;
}

export interface DBExtensionResponse {
  id: string;
  extensions: Extension[];
  updated_at: string;
}

export enum Severity {
  CRITICAL = 1,
  HIGH = 2,
  MEDIUM = 3,
  LOW = 4,
  INFO = 5
}
