import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';
import {wrapNumbersInBold} from "../../../utils/automated-investgiation/utils";
import TableItem from "../../Table/TableItem";

type Params = {
    pkData: { name: string, columns: { name: string, dataType: string }[] }
};

const PrimaryKeySizeInsight: React.FC<TableInsightsProps> = ({ severity, params, defaultOpen}) => {

    const getDetails = () => {
        const { pkData } = params as Params;
        return (
            <div>
                <div>{pkData?.name}</div>
                {pkData?.columns && pkData?.columns.length > 0 && (
                    <TableItem
                        headHeight={'320px'}
                        data={
                            pkData?.columns.map((column: any) => ({
                                Name: column.name,
                                ['Data Type']: column.dataType
                            })) || []
                        }
                    />
                )}
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                A high primary key size in bytes can impact query performance, especially if the primary key is used as a foreign key in
                other tables.
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                To avoid these issues, it's recommended to use smaller data types for primary keys whenever possible. For example, using a
                serial data type instead of a bigint can significantly reduce the size of the primary key. Additionally, it's important to
                ensure that the primary key is optimized for the query workload of the database.
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Primary Key Size'} severity={severity} defaultOpen={defaultOpen}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default PrimaryKeySizeInsight;