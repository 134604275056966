import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';


const DeleteWithoutAWhere: React.FC<TableInsightsProps> = ({ severity}) => {
    const title = "DELETE without a WHERE";

    const getDetails = () => {
        return (
            <div>
                The <b><code style={{backgroundColor: '#E0E0E0'}}>DELETE</code></b> command doesn't contain
                a <b><code style={{backgroundColor: '#E0E0E0'}}>WHERE</code></b> clause.
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                <ol>
                    <li>
                        <b>Data Loss:</b> Without a <b><code
                        style={{backgroundColor: '#E0E0E0'}}>WHERE</code></b> clause, the entire table is affected,
                        potentially deleting critical data that cannot be recovered easily.
                    </li>
                    <li>
                        <b>Performance Issues:</b> Deleting all rows in a large table can lead to performance bottlenecks, especially in production environments.
                    </li>
                </ol>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b>Always Include a <code style={{backgroundColor: '#E0E0E0'}}>WHERE</code> Clause:</b> Ensure that
                a <b><code style={{backgroundColor: '#E0E0E0'}}>WHERE</code></b> clause is used to specify which rows
                should be deleted, making the operation more controlled and
                intentional.<br/>
                Example: <code style={{backgroundColor: '#E0E0E0'}}>DELETE FROM employees WHERE employee_id = 123;</code>
            </div>
        )
    }

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default DeleteWithoutAWhere;
