import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    rowsSorted: number;
    sortKeys: string[]
};

const HighNumberOfSortedRowsReturned: React.FC<TableInsightsProps> = ({ severity, params}) => {
    const title ='High Number Of Sorted Rows Returned'

    const getDetails = () => {
        const { rowsSorted, sortKeys } = params as Params || {};
        return (
            <div>
                The SQL command sorted: <b>{rowsSorted.toLocaleString() || 'N/A'}</b> rows.
                Sort keys: <b>{sortKeys?.length ? sortKeys.join(', ') : 'None'}</b>
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                Sorting a large number of rows can slow down the query, requiring CPU and memory resources.
                It may lead to memory spills if the server needs to sort a very large dataset.
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <ul>
                    <li>
                        Limit the result set by applying filters or using pagination.
                    </li>
                    <li>
                        Optimize indexing for the sorting columns.
                    </li>
                    <li>
                        Consider caching pre-sorted results for static data.
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default HighNumberOfSortedRowsReturned;
