import {useRecoilState, useRecoilValue} from 'recoil';
import {useEffect, useState, useMemo, useContext, useCallback} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Skeleton, Tab, Tabs } from '@mui/material';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { reportsStateAtom, reportsStateI } from '../../../atoms/reports.atom';
import Flex from '../../../core/Flex';
import { SeverityLabel } from '../../../components/SeverityLabel/SeverityLabel';
import { BodyDrawer, Container, DataGridUI, DataGridUIContainer, MainInnerDrawer, MainTitle, TabContainer, TableHeader, TitleDrawer } from '../Reports.styled';
import TableLoader from '../../../components/Loader/TableLoader';
import { DataIndex, ReportAtomState } from './types';
import {  MysqlIndexTabState, PostgreIndexTabState } from '../utils';
import { getIndexTrendsAPI, getIndexUsageAPI } from '../utils/ReportAPI';
import { RestClientContext } from '../../../auth/RestClientAuthProvider';
import { userSettingsAtom } from "../../../atoms/user.settings.atom";
import {extractApiKeyByHost, toNumbersSuffix} from "../../../utils/utils";
import { TabPanel } from '../../../core/Tabs/Tabs';
import TableItem from '../../../components/Table/TableItem';
import { InsightsAccordionList } from '../../SchemaAnalysis/InsightsAccordionList';
import { RDBMS, SelectListItem, indexTrendListSelectedDates } from '../../../utils/constants';
import { HChart } from '../../ObservabilityDashboard/components';
import Select from '../../../components/Select/Select';
import { getSeverityCircle } from '../../SchemaAnalysis/ListTree';
import Search from '../../../core/Search';
import Typography from '../../../core/Typography';
import { ReactComponent as BinaryTreeIcon } from '@icons/binary-tree.svg';
import {useTheme} from "styled-components";
import IconTagLabel from "../../../core/Label/IconTagLabel";


export const IndexUsage = () => {
  const type: ReportAtomState = 'dataIndex';
  const restClient = useContext(RestClientContext);
  const ForgienKey = '🗝️ FK'
  const PrimaryKey = '🔑 PK'
  const { state }: any = useLocation();
  const { colors }: any = useTheme();
  const [rdbms, setRdbms] = useState(state?.rdbms);
  const [isLoading, setLoading] = useState(false);
  const [filterInsights, setFilterInsights] = useState(false);
  const [tabState, setTabState] = useState<number>(0);
  const [selectedDate, setSelectedDay] = useState<SelectListItem>(indexTrendListSelectedDates[0]);
  const [selecteSizeTrendsdDate, setSelectedSizeTrendsDay] = useState<SelectListItem>(indexTrendListSelectedDates[0]);
  const [indexTrend, setIndexTrend] = useState('ACTIVITY');
  const [insight, setInsight] = useState<any>([]);
  const [indexData, setIndexData] = useState<any>(undefined);
  const [indexActivityValues, setIndexAcitvityValues] = useState<any>(undefined);
  const [indexSizeValues, setIndexSizeValues] = useState<any>(undefined);
  const [indexRows,setIndexRows]: any = useState([])
  const [{ [type]: reportsState, selectedType }, setReportsState] = useRecoilState(reportsStateAtom);

  const handleTabChange = (event: any, newValue: number) => {
    setTabState(newValue);
  };

  const tabs = rdbms === RDBMS.postgres ? PostgreIndexTabState : MysqlIndexTabState;


  const { projectApiKey = '', hostId = '', databaseId = '' } = useParams();
  const settings = useRecoilValue(userSettingsAtom);
  const [filterSearchParams, setFilterSearchParams] = useState<string>('');


 
  useEffect(() => {
    const filterIndexes= reportsState?.rows?.filter((el: any) => el.context.index_name.includes(filterSearchParams) || filterSearchParams.length === 0 )

    setReportsState((old: reportsStateI) => ({
      ...old,
      ['dataIndex']: {...old['dataIndex'],  rows: filterSearchParams === '' ? indexRows :  filterIndexes}
    }));
  },[filterSearchParams])


  const getReports = async () => {
    setLoading(true);
    const indexUsageRes: { rows: DataIndex[]; lastUpdateIsoDate: string } | undefined = await getIndexUsageAPI(
      restClient,
      extractApiKeyByHost(hostId, settings.hostsApiKey),
      databaseId,
      selectedDate.val
    );
    setReportsState((old: reportsStateI) => ({
      ...old,
      selectedType: type,
      [type]: { rows: indexUsageRes?.rows || [], lastUpdateIsoDate: indexUsageRes?.lastUpdateIsoDate || '' }
    }));
    setIndexRows(indexUsageRes?.rows)
    setData(indexUsageRes?.rows?.[0])

    setLoading(false);
  };



  const getTrend = async (indexTrend: string) => {
    const res: { rows: DataIndex[]; lastUpdateIsoDate: string } | undefined = await getIndexTrendsAPI(
      restClient,
      extractApiKeyByHost(hostId, settings?.hostsApiKey),
      databaseId,
      indexTrend === 'ACTIVITY' ? selectedDate?.val : selecteSizeTrendsdDate?.val,
      indexTrend,
      indexData?.index_name,
      indexData?.table_name,
      (indexTrend === 'ACTIVITY' ? selectedDate?.timeFrame : selecteSizeTrendsdDate?.timeFrame) || ''
    );
    if(indexTrend === 'ACTIVITY') {
      setIndexAcitvityValues(res?.rows);
    }
    if(indexTrend === 'SIZE') {
      setIndexSizeValues(res?.rows)
    }
  };

  const capitalizeFirstLetter = (text: string): string => {
    if (!text) return text; 
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  


  useEffect(() => {
      getTrend(indexTrend);
  },[selectedDate.val, selecteSizeTrendsdDate.val, hostId, databaseId, indexTrend])

  useEffect(() => {
    getTrend('SIZE');
    getTrend('ACTIVITY');
},[indexData])


  useEffect(() => {
    if (selectedType !== type) {
      setReportsState((old) => ({
        ...old,
        selectedType: type,
        databaseId
      }));
    }
    if (hostId && databaseId) {
      getReports();
    }
  }, [hostId, databaseId]);

  const filteredData = useMemo(
    () => (!filterInsights ? reportsState.rows : reportsState.rows.filter((index: DataIndex) => index.context.insights)),
    [filterInsights, databaseId, reportsState.rows.length]
  );
  const insightsFormatter = (params: ValueFormatterParams) => {
    return params?.value?.length && <SeverityLabel priority={params.value[0]?.priority} />;
  };
  const nameFormatter = (params: ValueFormatterParams) => {
    const { value } = params;
    
    return <><span>{value.index_name}</span>
          <span style={{marginLeft: 7}}>
    {value?.insights?.length > 0  ? getSeverityCircle('#FFCC00'): <></>}
   </span></>;
  };

  const getRowStyle = (params: any) => {
    const selectedSchemaName = indexData?.schema_name;
    const selectedTableName = indexData?.table_name;
    const selectedIndexName = indexData?.index_name;
    if (
        selectedTableName === params?.data?.context?.table_name &&
        selectedSchemaName === params?.data?.context?.schema_name &&
        selectedIndexName === params?.data?.context?.index_name
    ) {
      return { background: colors?.new?.primary?.[150], fontWeight: 500 };
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Index Name',
      sortable: true,
      flex: 2,
      cellRenderer: nameFormatter,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) => params.data?.context
    },
    {
      headerName: 'Table Name',
      sortable: true,
      flex: 2,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      valueGetter: (params: any) =>
          `${params.data?.context?.schema_name ? params.data.context.schema_name + '.' : ''}${params.data?.context?.table_name}`
    },
    {
      field: 'history',
      headerName: 'Daily Usage',
      sortable: false,
      flex: 2,
      valueGetter: (params: any) => params.data?.values,
      cellRenderer: ({ value }: ValueFormatterParams) => <></>
    },
    {
      headerName: 'Insights',
      sortable: true,
      sort: 'desc',
      flex:2,
      cellStyle: { justifyContent: 'start', alignItems: 'center', display: 'flex' },
      cellRenderer: insightsFormatter,
      valueGetter: (params: ValueFormatterParams) => params?.data?.context?.insights,
      comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any) => {
        const priorityA = nodeA?.data?.context?.insights?.[0]?.priority || 0;
        const priorityB = nodeB?.data?.context?.insights?.[0]?.priority || 0;
        if (priorityA === priorityB) return 0;
        return priorityA > priorityB ? 1 : -1;
      }
    }
  ]);

  const setData = (data: any) => {
    setInsight(data?.insights || data?.context?.insights);
    setIndexData(data?.context);
  }

  const onSelectDate = (val: any, indexTrend: string): void => {
    const selected = indexTrendListSelectedDates.find((i) => i.val === val) || indexTrendListSelectedDates[0];
    setIndexTrend(indexTrend)
    if(indexTrend === 'ACTIVITY') {
      setSelectedDay(selected);
    }
    if(indexTrend === 'SIZE') {
      setSelectedSizeTrendsDay(selected)
    }
  };

  return (
    <>
      <Container style={{display: 'flex', flexDirection: 'column',alignItems: 'start', justifyContent: 'flex-start'}}>
        <Search
              fullWidth
              width="34rem"
              minWidth="2rem"
              placeholder="Search Index"
              value={filterSearchParams}
              onSubmit={setFilterSearchParams}
              onChangeCallback={setFilterSearchParams}
              marginBottom='7px'
            />
        <DataGridUIContainer className="ag-theme-material">
          {isLoading ? (
            <TableLoader size={10} />
          ) : (
            <DataGridUI
              rowData={filteredData}
              animateRows={true}
              columnDefs={columnDefs}
              rowHeight={40}
              suppressCellFocus={true}
              getRowStyle={getRowStyle}
              onRowClicked={(row: any) => { row?.data?.context && setData(row.data);
              }}
            />
          )}
        </DataGridUIContainer>
        <MainInnerDrawer direction="column">
            <BodyDrawer width="45vw">
              <TableHeader justify="space-between">
                <TitleDrawer h3 weight="600">
                  {isLoading ? (
                    <Skeleton animation="pulse" variant="text" width="200px" height={30} />
                  ) : (
                    `${indexData?.index_name}` 
                   )}
                </TitleDrawer>
              </TableHeader>    
              <Tabs value={tabState} onChange={handleTabChange} sx={{ width: 'calc(100% - 24px)', pt: 1, m: '0 12px' }}>
                {tabs.map((tabItem: string) => (
                  <Tab key={tabItem} id={`report-tab-${tabItem}`} label={tabItem} />
                ))}
              </Tabs>
              <TabPanel value={tabState} index={0} style={{ width: 'inherit' }}>
                <TabContainer>
                  {
                  indexData?.data?.indexType &&
                  <div style={{marginBottom: 5, alignItems:"left"}}>
                    <IconTagLabel
                        icon={<BinaryTreeIcon height={20} width={20} />}
                        type={'primary'}
                        title="Index Type:"
                        boldText={capitalizeFirstLetter(indexData?.data?.indexType) || 'N/A'}
                        noBorder={true}
                        transparentBackground={false}
                        customPadding="2px 8px 2px 2px"
                        customHeight="auto"
                    />
                  </div>
                  }
                <Typography h4>{indexData?.data?.relatedColumns?.length ? "Columns" : "Expression"}</Typography>
                  <Flex fw>
                      <TableItem
                          data={
                            indexData?.data?.relatedColumns?.length
                                ? indexData?.data?.relatedColumns?.map((column: any) => ({
                                  ['Column Name']: column.name,
                                  ['Data Type']: column.dataType,
                                  ['Ref']: column.isForeignKey && column.isPrimaryKey
                                      ? `${PrimaryKey} + ${ForgienKey}`
                                      : column.isForeignKey
                                          ? ForgienKey
                                          : column.isPrimaryKey
                                              ? PrimaryKey
                                              : ''
                                }))
                                : indexData?.data?.relatedExpressions?.map((expression: string) => ({
                              ['Expression Name']: indexData?.data?.name,
                              ['Data Type']: 'text', // or 'text', depending on your needs
                              ['Ref']: expression
                            })) || []
                          }
                        emptyErrorMessage="No Data was found"
                        headHeight="190px"
                      />
                  </Flex>
                </TabContainer>
              </TabPanel>
              {rdbms === RDBMS.postgres &&   <TabPanel value={tabState} index={1} style={{ width: 'inherit' }}>
                <TabContainer>
                <Flex align="start" margin="0 0 16px 0">
                    <Flex margin="4px 12px 0 0">
                      <MainTitle weight="500">Last</MainTitle>
                    </Flex>
                    <Select
                      name=""
                      minWidth={'230px'}
                      isSorted={false}
                      value={selecteSizeTrendsdDate.val}
                      list={indexTrendListSelectedDates}
                      onSelect={(val) => onSelectDate(val, 'SIZE')}
                    />
                  </Flex>
                    <HChart
                      data={indexSizeValues}
                      valueName={['Size']}
                      formatFunc={(value: number) => `${toNumbersSuffix(value).num} KB`}
                      isLoading={false}
                      height={250}
                      type={'bar'}
                      dateFormatType={'custom'}
                    />
                </TabContainer>
              </TabPanel>
              }        
              <TabPanel 
                value={tabState} 
                index={rdbms === RDBMS.postgres ? 2 : 1} 
                style={{ width: 'inherit' }}
              >
                <TabContainer>
                  <Flex align="start" margin="0 0 16px 0">
                    <Flex margin="4px 12px 0 0">
                      <MainTitle weight="500">Last</MainTitle>
                    </Flex>
                    <Select
                      name=""
                      minWidth={'230px'}
                      isSorted={false}
                      value={selectedDate.val}
                      list={indexTrendListSelectedDates}
                      onSelect={(val) => onSelectDate(val, 'ACTIVITY')}
                    />
                  </Flex>
                    <HChart
                      data={indexActivityValues}
                      valueName={['Scan']}
                      formatFunc={(value: number) => `${toNumbersSuffix(value).num} ${toNumbersSuffix(value).suffix}`}
                      isLoading={false}
                      height={250}
                      type={'bar'}
                      dateFormatType={'custom'}
                    />
                </TabContainer>
              </TabPanel>
              <TabPanel 
                value={tabState} 
                index={rdbms === RDBMS.postgres ? 3 : 2} 
                style={{ width: 'inherit' }}
              >
                <TabContainer>    
                    <InsightsAccordionList 
                      dataType={'INDEXES'} 
                      rdbms={rdbms as any} 
                      indexReportAssertions={true} 
                      indexInsights={insight} 
                    />
                </TabContainer>
              </TabPanel>
            </BodyDrawer>
          </MainInnerDrawer>
      </Container>
    </>
  );
};
