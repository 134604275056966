import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    schemaName: string;
    tableName: string;
};

const MissingTableStatisticsInsight: React.FC<TableInsightsProps> = ({ severity, params, defaultOpen}) => {

    const getDetails = () => {
        const { schemaName, tableName } = params as Params;
        return (
            <div>
                The table {schemaName}.{tableName} does not have any statistics.
                Normally, the database server manages statistics using the auto analyze configuration.
                However, in the case of large tables, statistics may need to be created manually to ensure optimal query performance.
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li><b>Suboptimal Query Performance:</b> Without statistics, the query planner cannot make informed
                        decisions,
                        leading to inefficient query plans and slower query performance.
                    </li>
                    <li><b>Increased Resource Usage:</b> Poor query plans can result in higher CPU, memory, and I/O
                        usage,
                        affecting overall database performance and potentially impacting other operations.
                    </li>
                    <li><b>Inconsistent Query Results:</b> In the absence of accurate statistics, the database may
                        produce varying
                        query performance, causing unpredictability in application response times.
                    </li>
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        const { schemaName, tableName } = params as Params;

        return (
            <div>
                <ul>
                    <li>
                        <b>Verify Missing Statistics:</b> Confirm that the table table_name indeed lacks statistics by
                        checking the system catalog.
                        <div style={{
                            backgroundColor: '#f4f4f4',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <code>SELECT schemaname, relname, last_autoanalyze, last_analyze
                                FROM pg_stat_all_tables
                                WHERE schemaname NOT IN ('pg_toast', 'pg_catalog', 'information_schema');</code></div>
                    </li>
                    <li>
                        <b>Manually Analyze the Table:</b> Use the ANALYZE command to manually generate statistics for
                        the table.
                        checking the system catalog.
                        <div style={{
                            backgroundColor: '#f4f4f4',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd'
                        }}>
                            <code>ANALYZE {schemaName}.{tableName};</code></div>
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <InsightsAccordion title={'Missing Table Statistics'} severity={severity} defaultOpen={defaultOpen}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                        <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default MissingTableStatisticsInsight;