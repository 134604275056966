import React from 'react';
import {ConfigurationInsightsProps} from "../types";
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {FlexFullWrapper, IconWrapper} from '../Insights.styled'
import {Container} from '../Insights.styled';

const EffectiveIOConcurrencyInsight: React.FC<ConfigurationInsightsProps> = ({ severity, params}) => {

    const getDetails = () => {
        return (
            <div>
                <code style={{backgroundColor: '#E0E0E0'}}>effective_io_concurrency</code> specifies the number of
                concurrent disk I/O operations PostgresSQL can issue for sequential scans and bitmap heap scans.
                It optimizes how PostgresSQL utilizes storage I/O for maximum efficiency.
                <ul>
                    <li>
                        <code style={{backgroundColor: '#E0E0E0'}}>work_mem:</code> Impacts memory allocation for sorts and
                        joins; often tuned alongside effective_io_concurrency for query performance.
                    </li>
                    <li>
                        <code style={{backgroundColor: '#E0E0E0'}}>random_page_cost:</code> Affects the cost model for planner
                        decisions; use with effective_io_concurrency to balance sequential vs. random access.
                    </li>
                    <li>
                        <code style={{backgroundColor: '#E0E0E0'}}>maintenance_work_mem:</code> For maintenance tasks like
                        vacuuming and index builds that may also involve I/O.
                    </li>
                </ul>
            </div>
        )
    }

    const getImpact = () => {
        return (
            <div>
                <ol>
                    <li><b>Too Low:</b>
                        <ul>
                            <li>The system underutilizes storage bandwidth.</li>
                            <li>Slower query execution for large sequential scans and bitmap heap scans.</li>
                        </ul>
                    </li>
                    <li><b>Too High:</b>
                        <ul>
                            <li>Increased contention and I/O queuing.</li>
                            <li>Performance degradation due to excessive parallel I/O requests, especially on storage with limited IOPS capacity.</li>
                        </ul>

                    </li>
                </ol>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                <b style={{display: 'inline-block', marginBottom: '0.1em'}}>Recommended Values:</b>
                <ul style={{marginTop: 0, paddingLeft: '20px'}}>
                    <li><b>SSD-based storage:</b> Set between 50 and 200.</li>
                    <li><b>HDD-based storage:</b> Set between 2 and 4.</li>
                    <li><b>Network-attached storage (e.g., EBS):</b> Use values consistent with the IOPS capacity of the
                        storage.<br/> For AWS EBS gp3 volumes, for instance, set values in proportion to provisioned IOPS.
                    </li>
                </ul>
                <b style={{display: 'inline-block', marginBottom: '0.1em'}}>Special Considerations for cloud platforms
                    like
                    AWS, Azure, or GCP:</b><br/>
                <b style={{display: 'inline-block', marginRight: '20px'}}>EBS Volumes (AWS):</b>
                <ul style={{marginTop: 0, marginBottom: '0.2em', paddingLeft: '20px'}}>
                    <li>
                        For gp2 or gp3 volumes, consider IOPS limits when setting the value.<br/>
                        A gp3 volume with 3,000 IOPS might use a value of 50–100.
                    </li>
                    <li>
                        For io1 or io2 volumes, the setting can be higher, reflecting higher provisioned IOPS.
                    </li>
                </ul>
                <b style={{display: 'inline-block', marginRight: '20px'}}>Shared Network Storage:</b>
                <ul style={{marginTop: 0, paddingLeft: '20px'}}>
                    <li>Evaluate the storage performance characteristics and set a conservative value to avoid
                        overloading the storage backend.
                    </li>
                </ul>
                <b style={{display: 'inline-block', marginRight: '20px'}}>To configure <code style={{backgroundColor: '#E0E0E0'}}>effective_io_concurrency</code> effectively:</b>
                <ol style={{marginTop: '0.1em', paddingLeft: '20px'}}>
                    <li>
                        <b>Determine Your Storage Capabilities:</b> Identify the type of storage used (SSD, HDD, or network storage).
                        <br/>For AWS EBS, check the IOPS capacity of your volume.
                    </li>
                    <li>
                        <b>Edit the Configuration:</b> Set an appropriate value based on storage capabilities.
                        <br/> For example: <code style={{backgroundColor: '#E0E0E0'}}>SET effective_io_concurrency TO 100;</code>
                    </li>
                    <li>
                        <b>Reload PostgresSQL Configuration:</b> Unlike some parameters, <code
                        style={{backgroundColor: '#E0E0E0'}}>effective_io_concurrency</code> does not require a server
                        restart.
                        Reload the configuration: <code style={{backgroundColor: '#E0E0E0'}}>SELECT
                        pg_reload_conf();</code>
                    </li>
                    <li>
                        <b>Verify the Change:</b> Ensure the setting has been applied correctly, <code style={{backgroundColor: '#E0E0E0'}}>SHOW effective_io_concurrency;</code>
                    </li>
                    <li><b>Monitor Performance</b> using Metis Monitoring page.</li>
                </ol>
            </div>
        )
    }
    return (
        <InsightsAccordion title={'effective_io_concurrency'} severity={severity} defaultOpen>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default EffectiveIOConcurrencyInsight;