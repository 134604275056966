import React from 'react';
import NumberOfIndexes from "./NumberOfIndexes";
import NoIndexes from "./NoIndexes";
import MissingTableStatistics from "./MissingTableStatistics";
import MissingPrimaryKey from "./MissingPrimaryKey";
import FKDataTypeMatchPKInsight from "./FKDataTypeMatchPK";
import FKMissingIndexInsight from "./FKMissingIndex";
import DuplicateIndexes from "./DuplicateIndexes";
import PrimaryKeyNumberOfColumnsInsight from "./PrimaryKeyNumberOfColumns";
import PrimaryKeySizeInsight from "./PrimaryKeySize";
import NumberOfDeadRowsInsight from "./NumberOfDeadRows";

const TableInsightTypes = {
    NUM_OF_INDEXES: 'NUM_OF_INDEXES',
    PRIMARY_KEY_MISSING: 'PRIMARY_KEY_MISSING',
    MISSING_STATISTICS: 'MISSING_STATISTICS',
    NO_INDEXES: 'NO_INDEXES',
    FOREIGN_KEY_TYPE_MATCH: 'FOREIGN_KEY_TYPE_MATCH',
    FOREIGN_KEY_HAS_INDEX: 'FOREIGN_KEY_HAS_INDEX',
    DUPLICATE_INDEXES: 'DUPLICATE_INDEXES',
    PRIMARY_KEY_NUM_OF_COLUMNS: 'PRIMARY_KEY_NUM_OF_COLUMNS',
    PRIMARY_KEY_SIZE: 'PRIMARY_KEY_SIZE',
    NUM_OF_DEAD_ROWS: 'NUM_OF_DEAD_ROWS',
} as const;

type TableInsightTypes = typeof TableInsightTypes[keyof typeof TableInsightTypes];

export type TableInsightsProps = {
    type: TableInsightTypes | string;
    severity: number;
    params: object;
}

type TableInsightsMapperProps = {
    insights: TableInsightsProps[];
};

const TableInsightsMapper: React.FC<TableInsightsMapperProps> = ({insights}) => {
    const openAccordion = insights.length === 1;

    return insights.sort((a,b) => a.severity > b.severity ? 1 : -1).map((insight, index) => {
        const { type, severity, params } = insight;
        const isDefaultOpen = openAccordion && index === 0;

        switch (type) {
            case TableInsightTypes.NUM_OF_INDEXES:
                return (
                    <NumberOfIndexes
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.PRIMARY_KEY_MISSING:
                return (
                    <MissingPrimaryKey
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />

                )
            case TableInsightTypes.MISSING_STATISTICS:
                return (
                    <MissingTableStatistics
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.NO_INDEXES:
                return (
                    <NoIndexes
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.FOREIGN_KEY_TYPE_MATCH:
                return (
                    <FKDataTypeMatchPKInsight
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.FOREIGN_KEY_HAS_INDEX:
                return (
                    <FKMissingIndexInsight
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.DUPLICATE_INDEXES:
                return (
                    <DuplicateIndexes
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.PRIMARY_KEY_NUM_OF_COLUMNS:
                return (
                    <PrimaryKeyNumberOfColumnsInsight
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.PRIMARY_KEY_SIZE:
                return (
                    <PrimaryKeySizeInsight
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            case TableInsightTypes.NUM_OF_DEAD_ROWS:
                return (
                    <NumberOfDeadRowsInsight
                        severity={severity}
                        params={params}
                        defaultOpen={isDefaultOpen}
                    />
                )
            default:
                return null;
        }

    })
};

export default TableInsightsMapper;