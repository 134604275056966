import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CelWrapper, Cell, ErrorItem, Container, FilterCell } from './TableItem.styled';
import { ReactComponent as NoRecentActivityData } from '@icons/no-data-found-traces.svg';

import Typography from '../../core/Typography';

import { getFormattedDate, numberWithCommas } from '../../utils/utils';
import { theme } from '../../theme';
import Flex from '../../core/Flex';
import MTooltip from '../../core/Tooltip/Tooltip';
import NoData from '../NoData/NoData';
import { byteMe } from '../../utils/byteMe';

interface TableItemProps {
  data: Array<any>;
  emptyErrorMessage?: string;
  headHeight?: string;
}

const TableItem = ({ data, emptyErrorMessage = 'No Data', headHeight }: TableItemProps) => {
  if (!(data && data.length)) {
    return <NoData title={emptyErrorMessage} subTitle={''} icon={<NoRecentActivityData />}></NoData>;
  }

  const customizeCell = (item: any, idx: number, tableLength: number): any => {
    if (item === 'Table Scan' || item === 'Seq Scan') {
      return { color: theme.colors.tablesInfo.alert };
    }

    if (item === 'Index Only Scan') {
      return { color: '#03C04A' };
    }

    if (idx === 0) {
      return { marginRight: 0, paddingRight: 0 };
    }

    if (idx === tableLength - 1) {
      return { marginRight: 0, paddingRight: 0 };
    }

    return {};
  };

  const rows = data;

  const cellItem = (item: any, key: string) => {
    switch (key) {
      case 'Error':
        return <ErrorItem>{String(item)}</ErrorItem>;
      case 'Created Date':
        return <Typography>{getFormattedDate(item)}</Typography>;
      case 'Is PK':
        return <FilterCell weight={item === 'Yes' ? 600 : 400}>{item}</FilterCell>;
      case 'Access Method':
        return <Flex justify="flex-start">{String(item)}</Flex>;
      case 'Filter':
        return (
          <MTooltip title={item} disable={item && item.length < 20}>
            <FilterCell>{item}</FilterCell>
          </MTooltip>
        );
      default:
        return item === null ? '' : String(item);
    }
  };
  return (
    <Container head_height={headHeight} style={{overflowX: 'auto'}}>
      <Table stickyHeader aria-label="sticky table" size="small" style={{ tableLayout: 'auto', width: '100%' }}>
        <TableHead>
          <TableRow style={{ height: '2rem' }}>
            {data.length !== 0 &&
              Object.keys(data[0]).map((item, idx) => {
                return (
                  <CelWrapper key={idx} align="left" >
                    <Typography weight={600}>{item}</Typography>
                  </CelWrapper>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((fact: any, idx: number) => (
            <TableRow key={idx}>
              {Object.values(fact).map((item: any, i: number, arr: any) => (
                <Cell sx={customizeCell(item, i, arr.length)} key={i} align="left">
                  {cellItem(item, Object.keys(fact)[i])}
                </Cell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default TableItem;

export const transformData = (tablesInfo: any[]) => {
  return tablesInfo.map((table: any) => {
    if (table['Buffers'] && table['Timing']) {
      table['Buffers'] = typeof table['Buffers'] === 'number' ? byteMe((table['Buffers'] || 0) * 8 * 1024) : 'unknown';
      table['Timing'] = table['Timing'] === 'unknown' ? 'unknown' : `${numberWithCommas(parseInt(table['Timing']))} ms`;
    }
    if (table['Cost']) {
      table['Cost'] = typeof table['Cost'] === 'string' ? table['Cost'] : numberWithCommas(table['Cost']);
    }
    if (table['Rows Filtered']) {
      table['Rows Filtered'] =
        typeof table['Rows Filtered'] === 'string' ? table['Rows Filtered'] : numberWithCommas(table['Rows Filtered']);
    }
    if (table['Rows Read']) {
      table['Rows Read'] = typeof table['Rows Read'] === 'string' ? table['Rows Read'] : numberWithCommas(table['Rows Read']);
    }

    return table;
  });
};
