import React from 'react';
import InsightsAccordion from "../InsightsAccordion";
import {Details, DetailsWrapper} from "../../InvestigationDetail/InvestigationDetail.styled";
import { ReactComponent as InsightsDetailsIcon } from '@icons/insight-details-icon.svg';
import {Label} from "../../Ruler/Ruler.styled";
import { ReactComponent as Pendulum } from '@icons/pendulum.svg';
import { ReactComponent as Wrench } from '@icons/wrench.svg';
import {TableInsightsProps} from "../types";
import {Container, FlexFullWrapper, IconWrapper} from '../Insights.styled';

type Params = {
    avgRowSize: number,
    rowsReturned: number,
    totalSizeMB: number
};

const ResultSetSize: React.FC<TableInsightsProps> = ({ severity, params}) => {
    const title = 'Result Set Size';

    const getDetails = () => {
        const { avgRowSize, rowsReturned,  totalSizeMB} = params as Params || {};
        return (
            <div>
                Avg row size: <b>{avgRowSize.toLocaleString()}.</b><br/>
                Rows returned: <b>{rowsReturned.toLocaleString()}.</b><br/>
                Total size of the results set: <b>{totalSizeMB.toFixed(2)} MB.</b><br/>
                <b>Notice!</b> Execution plans may inaccurately estimate sizes for JSON and long strings;<br/>
                real-world testing is advised for accurate sizing.
            </div>
        );
    };

    const getImpact = () => {
        return (
            <div>
                <ul>
                    <li>
                        <b>Query Performance:</b> Large result sets increase processing and I/O demands, slowing down queries.
                    </li>
                    <li>
                        <b>Network Latency:</b> Transferring large result sets can cause delays and slow application response times.
                    </li>
                    <li>
                        <b>Client-Side Handling:</b> Large result sets can overwhelm the client, causing performance issues or crashes.
                    </li>
                </ul>
            </div>
        )
    }

    const getRemediation = () => {
        return (
            <div>
                Review and optimize the most frequently executed queries for performance (e.g. by adding indexes or adjusting JOIN conditions).
                <ol>
                    <li>
                        <b>Limit the Result Set:</b> Use <code style={{backgroundColor: '#E0E0E0'}}>LIMIT</code> to
                        restrict the number of rows returned by the query.
                    </li>
                    <li>
                        <b>Use Pagination:</b> Break down the result set into smaller, manageable chunks with <code style={{backgroundColor: '#E0E0E0'}}>LIMIT</code> or <code
                        style={{backgroundColor: '#E0E0E0'}}>OFFSET</code> similar methods.
                    </li>
                    <li>
                        <b>Review Wide Columns:</b> Carefully review and avoid returning wide columns, such as JSON or images, unless absolutely necessary. Instead, retrieve only the essential data.
                    </li>
                    <li>
                        <b>Filter Data:</b> Apply more specific <code style={{backgroundColor: '#E0E0E0'}}>WHERE</code> clauses to narrow down the result set to only the necessary data.
                    </li>
                </ol>
            </div>
        )
    }

    return (
        <InsightsAccordion title={title} severity={severity}>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <InsightsDetailsIcon width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Insight Details
                        </Label>
                        <DetailsWrapper>
                            <Details>{getDetails()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Pendulum width={24}/>
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Impact
                        </Label>
                        <DetailsWrapper>
                            <Details>{getImpact()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
            <Container justify={'flex-start'}>
                <FlexFullWrapper justify="start" align="start" margin="8px 0">
                    <IconWrapper>
                        <Wrench width={24} />
                    </IconWrapper>
                    <FlexFullWrapper direction="column" align="start">
                        <Label weight={600} h4>
                            Remediation Plan
                        </Label>
                        <DetailsWrapper>
                            <Details>{getRemediation()}</Details>
                        </DetailsWrapper>
                    </FlexFullWrapper>
                </FlexFullWrapper>
            </Container>
        </InsightsAccordion>
    );
};

export default ResultSetSize;
